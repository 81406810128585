import ScrollOut from 'scroll-out'
import { state } from '../modules/state'

export default class {
  constructor() {
    this.bindEvent();
    this.init()
  }
  init() {
    window.onload = ()=> {
      document.querySelector('.c-init').classList.add('is-loaded')
    }
  }
  bindEvent() {
    ScrollOut({
      onShown: (element, ctx, scrollingElement) => {
        element.classList.add('animated')
      }
    })
  }
}