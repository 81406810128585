import { state } from '../modules/state'

export default class {
  constructor() {
    this.spMenuTrigger = document.querySelector('.c-gHeader__spMenuTrigger')
    this.globalMenu = document.querySelector('.c-gHeader__menu')

    this.bindEvent()
  }
  bindEvent() {
    this.spMenuTrigger.addEventListener(
      'click',
      event => {
        if (state.isOpenMenu !== true) {
          this.spMenuTrigger.classList.add('is-active')
          this.globalMenu.classList.add('is-active')
          state.isOpenMenu = true
        } else {
          this.spMenuTrigger.classList.remove('is-active')
          this.globalMenu.classList.remove('is-active')
          state.isOpenMenu = false
        }
      },
      false
    )
  }
}