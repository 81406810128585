import Home from './view/Home'
import Work from './view/Work'
import Contact from './view/Contact'
import Commons from './view/Commons'
import GlobalMenu from './modules/GlobalMenu'

document.addEventListener('DOMContentLoaded', ()=>{
  route(location.pathname);
},false);

const route = (pathname)=> {
  new GlobalMenu()

  if(pathname === '/') {
    new Home()
  }else if(pathname === '/contact/') {
    new Contact()
  }else if(pathname.match(/work/gi)) {
    new Work()
  }else {
    new Commons()
  }
}