import { state } from '../modules/state'

export default class {
  constructor() {
    this.init()
  }
  init() {
    window.onload = ()=> {
      document.querySelector('.c-init').classList.add('is-loaded')
    }
  }
}