import Swiper from 'swiper';
import ScrollOut from 'scroll-out'
import {
  TweenMax,
  CSSPlugin,
  ScrollToPlugin,
  Elastic
} from 'gsap/all';
import {
  state
} from '../modules/state'
import axios from 'axios';

export default class {
  constructor() {
    this.mainvisualSquares = document.querySelectorAll('.c-mainvisual--square')
    this.workSquares = document.querySelectorAll('.c-work--square .c-work__link')
    this.workModal = document.querySelector('.c-modal')
    this.closeBtn = document.querySelector('.c-modal__wrapper__close')
    this.loadingWin = document.querySelector('.c-loading')
    this.html = document.querySelector('html');
    this.mql = window.matchMedia('screen and (max-width: 640px)')
    this.sliderMainvisual = new Swiper(
      '#sliderMainvisual', {
        init: false,
        accessibility: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        speed: 1200,
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: false
        },
        pagination: {
          clickable: true,
          el: '.p-home__mainvisuals__pagination'
        }
      },
      false
    )
    this.sliderModal = new Swiper(
      '#sliderModal', {
        init: false,
        slidesPerView: 2,
        spaceBetween: 20,
        navigation: {
          nextEl: '.p-home__workDetail__slider__button--next',
          prevEl: '.p-home__workDetail__slider__button--prev'
        },
        centeredSlides: true,
        breakpoints: {
          1420: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          1419: {
            slidesPerView: 'auto',
            spaceBetween: 30,
            centeredSlides: true
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true
          }
        }
      },
      false
    )

    this.bindEvents()
    this.init()
  }
  init() {
    window.onload = () => {
      document.querySelector('.c-init').classList.add('is-loaded')
    }
  }
  bindEvents() {
    ScrollOut({
      onShown: (element, ctx, scrollingElement) => {
        if (element.classList.contains('p-home__works')) {
          TweenMax.staggerFromTo(
            '.c-work',
            0.4, {
              opacity: 0,
              scale: 0.8
            }, {
              opacity: 1,
              scale: 1
            },
            0.1
          )
        }
        element.classList.add('animated')
      }
    })

    this.closeBtn.addEventListener('click', this.toggleModal.bind(this), false)

    this.workSquares.forEach(element => {
      element.addEventListener('click', this.onOpenModal.bind(this), true)
    })
    this.mainvisualSquares.forEach(element => {
      element.addEventListener('click', this.onOpenModal.bind(this), true)
    })

    const mql = window.matchMedia('screen and (max-width: 640px)')
    const checkBreakPoint = mql => {
      if (mql.matches) {
        this.sliderModal.update()
      }
    }
    mql.addListener(checkBreakPoint)

    this.sliderMainvisual.init()
    this.sliderModal.init()
  }

  onOpenModal(event) {
    const targetID = event.currentTarget.getAttribute('data-id');
    const trackPageURL = event.currentTarget.getAttribute('href');
    const disabledModalTouchMove = this.disabledModalTouchMove.bind(this);

    // 初期化
    this.loadingWin.classList.add('is-loading')
    this.sliderModal.slideTo(0)
    this.workModal.removeEventListener('touchmove', disabledModalTouchMove);

    axios.get('/wp-json/wp/v2/posts/' + targetID)
      .then((response) => {
        return this.createModalContent(response)
      }).then(() => {
        this.sliderModal.update()

        this.resizeModalTxt()
        this.setModalTxtWidth()

        this.loadingWin.classList.remove('is-loading')
        this.toggleModal()

        this.workModal.addEventListener('touchmove', disabledModalTouchMove, {
          passive: false
        });
      })

    // トラッキング用コード
    this.setAnalyticsDataLayer(trackPageURL)
    event.preventDefault()
  }

  createModalContent(response) {
    const acfData = response.data.acf;
    const sliderTarget = document.querySelector('.p-home__workDetail__slider .swiper-wrapper')
    const titleEnTarget = document.querySelector('.p-home__workDetail__title_en')
    const titleJaTarget = document.querySelector('.p-home__workDetail__title_ja')
    const descTarget = document.querySelector('.p-home__workDetail__desc')
    const promiseChainArray = []

    sliderTarget.innerHTML = ''
    titleEnTarget.innerHTML = ''
    titleJaTarget.innerHTML = ''
    descTarget.innerHTML = ''
    titleEnTarget.textContent = acfData.title_en
    titleJaTarget.textContent = acfData.title_jp
    descTarget.textContent = acfData.desc

    // imageのロードを同期的に読み込ませるためのPromiseを作成
    acfData.images.forEach((element) => {
      const loadImgCallback = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = element.sizes.large;

        img.addEventListener('load', () => {
          const slide = document.createElement('div');
          slide.classList.add('swiper-slide')
          slide.innerHTML = '<img src="' + element.sizes.large + '">';
          resolve(slide)
        }, false);
      });

      promiseChainArray.push(loadImgCallback)
    })

    // 読み込みがすべて完了したら、順番通りにスライドを設定
    return Promise.all(promiseChainArray).then(slides => {
      slides.forEach(element => {
        sliderTarget.appendChild(element)
      })
    })
  }

  setAnalyticsDataLayer(trackPageURL) {
    dataLayer.push({
      'trackPageview': trackPageURL,
      'event': 'loadready'
    });
  }

  toggleModal() {
    if (state.isOpenModal === false) {
      this.workModal.classList.add('is-active')
      this.html.classList.add('is-noScroll');
      state.isOpenModal = true
    } else {
      this.workModal.classList.remove('is-active')
      this.html.classList.remove('is-noScroll');
      state.isOpenModal = false
    }
  }

  disabledModalTouchMove(event) {
    const scroll = this.workModal.scrollTop;
    const range = this.workModal.scrollHeight - this.workModal.offsetHeight - 1;
    if (scroll < 1) {
      event.preventDefault();
      this.workModal.scrollTop = 1;
    } else if (scroll > range) {
      event.preventDefault();
      this.workModal.scrollTop = range;
    }
  }

  resizeModalTxt() {
    let resizeTimer;
    let interval = Math.floor(1000 / 60 * 10);

    window.addEventListener('resize', () => {
      // console.log('resizing');
      if (resizeTimer !== false) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {
        // console.log('resized');
        const mql = this.mql;
        const checkBreakPoint = () => {
          if (!mql.matches) {
            this.setModalTxtWidth()
          } else {
            this.removeTxtWidth();
          }
        }
        mql.addListener(checkBreakPoint)
        checkBreakPoint()

      }, interval);
    });
  }

  setModalTxtWidth() {
    const slideWidth = document.querySelector('.p-home__workDetail__slider .swiper-slide img').clientWidth;
    const workDetailTxt = document.querySelector('.p-home__workDetail__txts')

    workDetailTxt.style.width = (slideWidth - 20) + 'px'
  }

  removeTxtWidth() {
    const workDetailTxt = document.querySelector('.p-home__workDetail__txts')
    workDetailTxt.removeAttribute('style')
  }
}