import { state } from '../modules/state'
import axios from 'axios';

export default class {
  constructor() {
    this.confirmModal = document.querySelector('.c-modal')
    this.closeBtn = document.querySelector('.c-modal__wrapper__close')
    this.loadingWin = document.querySelector('.c-loading')
    this.formData = null;

    this.init()
  }
  init() {
    window.onload = ()=> {
      document.querySelector('.c-init').classList.add('is-loaded')
    }
    const modalClose = () => this.confirmModal.classList.remove('is-active')
    this.closeBtn.addEventListener('click', modalClose, false)

    document.querySelector('.c-button--confirm').addEventListener('click', this.onConfirm.bind(this), false)
    document.querySelector('.c-button--submit').addEventListener('click', this.onSubmit.bind(this), false)

  }
  onConfirm(event) {
    const formElements = document.querySelectorAll('.control');
    const tablearget = document.querySelector('.p-contact__confirm__inner__table')
    const validations = [];
    const form = document.forms.contact;
    this.formData = new FormData();

    // Validation
    formElements.forEach((element)=>{
      validations.push(element.validity.valid)
    });

    if(validations.indexOf(false) === -1) {
      event.preventDefault()

      tablearget.innerHTML = ''

      this.formData.append('type', form.type.value)
      this.formData.append('company', form.company.value)
      this.formData.append('name', form.name.value)
      this.formData.append('address', form.address.value)
      this.formData.append('phone', form.phone.value)
      this.formData.append('mail', form.mail.value)
      this.formData.append('content', form.content.value)
      this.formData.append('opportunity', form.opportunity.value)

      const tableKey = ['お問い合わせの種類','会社名','名前','住所','電話番号','Email','お問い合わせ内容','SPOT DESIGNを知ったきっかけ']
      let index = 0;

      this.formData.forEach((value)=> {
        const dl = document.createElement('dl');
        dl.innerHTML = '<dt>'+ tableKey[index] +'</dt><dd>'+ value +'</dd>';
        tablearget.appendChild(dl)
        index++;
      })

      if (!state.isOpenModal) {
        this.confirmModal.classList.add('is-active')
        state.isOpenModal = false
      } else {
        this.confirmModal.classList.remove('is-active')
        state.isOpenModal = true
      }
    }
  }
  onSubmit() {
    const csrf = document.getElementsByName('csrf-token').item(0).content;
    this.formData.append('csrfToken', csrf)
    this.loadingWin.classList.add('is-loading')

    axios.post('/api/contact.php', this.formData)
    .then((response)=> {
      if(response.data.status === 200) {
        location.href = '/contact/complete.php';
      }
    })

  }
}